import request from '@/utils/request'
import dayjs from 'dayjs'
import { MicroSvrAddrs } from '../common/config'
import type { DataResponse, OperationResponse, QueryResponse } from '../common/entity'
import { dataListTransformer, singleDataTransformer } from '../common/utils'

export enum OrgStatus {
  TRIAL = 'TRIAL', // 试用
  FORMAL = 'FORMAL', // 正式
  DISSOLVED = 'DISSOLVED' // 已解散
}

export const OrgStatusMap = new Map<OrgStatus, string>([
  [OrgStatus.TRIAL, '试用'],
  [OrgStatus.FORMAL, '正式'],
  [OrgStatus.DISSOLVED, '已解散']
])

export interface Organization {
  id: number
  parentId: number
  name: string
  description?: string
  status: OrgStatus
  ownerId: number
  workTimeInHours: number
  workTimeStart: string
  workTimeEnd: string
  createTime: Date
  updatedTime?: Date
}

const orgRequestTransformer = (data: any): Organization => {
  const startArr = data.workTimeStart as Array<number | string>
  data.workTimeStart = `${startArr[0]}:${startArr[1].toString().padStart(2, '0')}${startArr[2]}`
  const endArr = data.workTimeEnd as Array<number | string>
  data.workTimeEnd = `${endArr[0]}:${endArr[1].toString().padStart(2, '0')}${endArr[2]}`
  data.createTime = dayjs.unix(data.createTime).toDate()
  data.updatedTime = data.updatedTime ? dayjs.unix(data.updatedTime).toDate() : undefined
  return data as Organization
}

const singleOrgResponseTransformer = singleDataTransformer(orgRequestTransformer)

const orgListResponseTransformer = dataListTransformer(orgRequestTransformer)

// 查询可见团队列表
export async function fetchOrgList(): Promise<QueryResponse<Organization>> {
  return request.get(`${MicroSvrAddrs.taskania}/org`, {
    transformResponse: orgListResponseTransformer
  })
}

// 查询团队详情
export async function fetchOrgDetail(id: number): Promise<DataResponse<Organization>> {
  return request.get(`${MicroSvrAddrs.taskania}/org/${id}`, {
    transformResponse: singleOrgResponseTransformer
  })
}

export interface OrganizationReq {
  name: string
  description?: string
  status: OrgStatus
  workTimeInHours: number
  workTimeStart: string
  workTimeEnd: string
  paymentOrderNo?: string
}

// 创建团队
export async function createOrg(req: OrganizationReq): Promise<OperationResponse<number>> {
  return request.post(`${MicroSvrAddrs.taskania}/org`, req)
}

// 更新团队信息
export async function updateOrg(
  id: number,
  req: OrganizationReq
): Promise<OperationResponse<number>> {
  return request.put(`${MicroSvrAddrs.taskania}/org/${id}`, req)
}

// 升级为正式团队
export async function upgradeOrg(
  id: number,
  paymentOrderNo: string
): Promise<OperationResponse<number>> {
  return request.put(`${MicroSvrAddrs.taskania}/org/${id}/upgrade`, { paymentOrderNo })
}

// 解散团队
export async function dissolveOrg(id: number): Promise<OperationResponse<number>> {
  return request.delete(`${MicroSvrAddrs.taskania}/org/${id}`)
}
