<script setup lang="ts">
import { WX_WEB_AUTH_URL, fetchWxWebAuthState } from '@/api/auth'
import { useConfigStore } from '@/stores/config'
import { onMounted } from 'vue'

const props = defineProps({
  containerId: {
    type: String,
    required: false,
    default: 'wx_web_login_container'
  }
})

const configStore = useConfigStore()
const { systemIndexConfig } = configStore

onMounted(() => {
  if ('WxLogin' in window && systemIndexConfig?.login.wxWebAppId) {
    fetchWxWebAuthState().then((res) => {
      const state = res.data
      new window.WxLogin({
        self_redirect: true,
        id: props.containerId,
        appid: systemIndexConfig?.login.wxWebAppId,
        scope: 'snsapi_login',
        redirect_uri: WX_WEB_AUTH_URL,
        state: state,
        style: 'black'
      })
    })
  }
})
</script>

<template>
  <div :id="containerId" style="width: 300px; height: 400px"></div>
</template>
