import request from '@/utils/request'
import { MicroSvrAddrs } from '../common/config'
import type { CustomDict, DataResponse, QueryResponse } from '../common/entity'

// 系统配置实体
export interface SystemConfig<T> {
  id: number //  配置ID
  type: string //  配置类型
  item: string //  配置项
  value: T //  配置项值
  description: string //  配置描述
  orderNo: number //  排序号
}

// 查询系统配置列表
export async function querySystemConfigList<T = any>(
  types: string[]
): Promise<QueryResponse<SystemConfig<T>>> {
  return request.get(`${MicroSvrAddrs.taskania}/sys/config`, {
    params: {
      types: types.join(',')
    }
  })
}

// 查询系统配置项
export async function querySystemConfigItem<T>(
  type: string,
  item: string
): Promise<DataResponse<SystemConfig<T>>> {
  return request.get(`${MicroSvrAddrs.taskania}/sys/config/${type}/${item}`)
}

interface NameAndUrl {
  name: string
  url: string
}

export interface SystemIndexConfig {
  guides: {
    video: String
    guide: NameAndUrl[]
  }
  login: CustomDict<string>
  links: {
    groupName: string
    groupLinks: NameAndUrl[]
  }[]
  contacts: {
    desc: string
    kf: boolean
    pic: string
  }[]
}

export const CONFIG_TYPE_SYSTEM = 'system'
export const CONFIG_ITEM_SYSTEM_INDEX = 'index'
export const CONFIG_ITEM_SYSTEM_PRICING = 'pricing'
export const CONFIG_ITEM_SYSTEM_GUIDE = 'guide'

export type SystemPricingType = 'fre' | 'std' | 'adv' | 'pro'

export interface SystemPricingConfig {
  plans: {
    name: string
    code: SystemPricingType
    desc: string
    pricePerMonth: number
    highlights: string[]
  }[]
  comparisons: {
    group: string
    features: ({
      name: string
      type: 'Y/N' | 'NUM' | 'TXT' | 'SYS'
    } & Record<SystemPricingType, number | string | any>)[]
  }[]
}

export interface SystemGuideConfig {
  [key: string]: {
    doc: string
    video: string
  }
}
