import { fetchAccountProfile } from '@/api/account'
import { CONFIG_TYPE_SYSTEM, querySystemConfigList } from '@/api/system/config'
import { useConfigStore } from '@/stores/config'
import { useAccountStore } from '@/stores/user'
import { message } from 'ant-design-vue'
import { storeToRefs } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('../views/IndexView.vue'),
      redirect: () => ({ name: 'home' }),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('../views/index/HomeView.vue')
        },
        {
          path: 'pricing',
          name: 'pricing',
          component: () => import('../views/index/PricingView.vue')
        }
      ]
    },
    // 因为目前设计中，各个页面除了头部之外没有公共部分，所以暂时不使用嵌套路由
    // {
    //   path: '/workbench',
    //   children: [
    //     {
    //       path: '',
    //       name: 'workbench',
    //       component: () => import('../views/WorkbenchView.vue')
    //     },
    //     {
    //       path: 'task/:taskId',
    //       name: 'task',
    //       component: { template: '<p>task-detail</p>' }
    //     },
    //     {
    //       path: 'project/:projectId',
    //       name: 'project',
    //       redirect: (to) => ({ name: 'notice', params: { id: to.params.projectId } }),
    //       children: [
    //         {
    //           path: 'notice',
    //           name: 'notice',
    //           component: { template: '<p>project-notice</p>' }
    //         },
    //         {
    //           path: 'batch',
    //           name: 'batch',
    //           component: { template: '<p>project-requirement-batch</p>' },
    //           children: [
    //             {
    //               path: 'meeting/:meetingId',
    //               name: 'meeting',
    //               component: { template: '<p>project-requirement-batch-meeting</p>' }
    //             }
    //           ]
    //         },
    //         {
    //           path: 'pool',
    //           name: 'pool',
    //           component: { template: '<p>project-requirement-pool</p>' }
    //         },
    //         {
    //           path: 'milestone',
    //           name: 'milestone',
    //           component: { template: '<p>project-milestone</p>' }
    //         },
    //         {
    //           path: 'request',
    //           name: 'requirement-request',
    //           component: { template: '<p>project-requirement-request</p>' }
    //         },
    //         {
    //           path: 'participant',
    //           name: 'participant',
    //           component: { template: '<p>project-participant</p>' }
    //         }
    //       ]
    //     },
    //     {
    //       path: 'requirement/:requirementId',
    //       name: 'requirement',
    //       component: { template: '<p>requirement-detail</p>' },
    //       children: [
    //         {
    //           path: 'revision',
    //           name: 'revision',
    //           component: { template: '<p>requirement-revision</p>' }
    //         },
    //         {
    //           path: 'request',
    //           name: 'revision-request',
    //           component: { template: '<p>requirement-revision-request</p>' }
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   path: '/org',
    //   name: 'org',
    //   component: () => import('../views/OrganizationView.vue'),
    //   children: [
    //     {
    //       path: ':orgId/project',
    //       name: 'org-project',
    //       component: { template: '<p>org-project</p>' }
    //     }
    //   ]
    // },
    {
      path: '/org',
      name: 'org',
      component: () => import('../views/OrganizationView.vue')
    },
    {
      path: '/org/statistics/:orgId',
      name: 'org-statistics',
      component: () => import('../views/statistics/OrgStatisticsView.vue')
    },
    {
      path: '/record',
      name: 'record',
      component: () => import('../views/WorkRecordView.vue')
    },
    {
      path: '/message',
      name: 'message',
      component: () => import('../views/MessageView.vue')
    },
    {
      path: '/workbench',
      name: 'workbench',
      component: () => import('../views/WorkbenchView.vue')
    },
    {
      path: '/workbench/task/:taskId',
      name: 'task',
      component: () => import('../views/workbench/TaskDetailView.vue')
    },
    {
      path: '/workbench/project',
      name: 'project-manage',
      component: () => import('../views/workbench/ProjectManageView.vue')
    },
    {
      path: '/workbench/project/:projectId',
      name: 'project',
      redirect: (to) => ({ name: 'notice', params: { projectId: to.params.projectId } })
    },
    {
      path: '/workbench/project/:projectId/notice',
      name: 'notice',
      component: () => import('../views/workbench/ProjectNoticeView.vue')
    },
    {
      path: '/workbench/project/:projectId/batch',
      name: 'batch',
      component: () => import('../views/workbench/batch/BatchView.vue')
    },
    {
      path: '/workbench/project/:projectId/batch/adjust',
      name: 'batch-adjust',
      component: () => import('../views/workbench/batch/BatchAdjustView.vue')
    },
    {
      path: '/workbench/project/:projectId/batch/:batchId/meeting/:meetingId',
      name: 'meeting',
      component: () => import('../views/workbench/batch/BatchMeetingView.vue')
    },
    {
      path: '/workbench/project/:projectId/pool',
      name: 'pool',
      component: () => import('../views/workbench/RequirementPoolView.vue')
    },
    {
      path: '/workbench/project/:projectId/milestone',
      name: 'milestone',
      component: () => import('../views/workbench/MilestoneView.vue')
    },
    {
      path: '/workbench/project/:projectId/request',
      name: 'requirement-request',
      component: () => import('../views/workbench/RequirementRequestView.vue')
    },
    {
      path: '/workbench/project/:projectId/revision/request',
      name: 'project-revision-request',
      component: () => import('../views/workbench/ProjectRevisionRequestView.vue')
    },
    {
      path: '/workbench/requirement/:requirementId',
      name: 'requirement',
      component: () => import('../views/workbench/requirement/RequirementView.vue')
    },
    {
      path: '/workbench/requirement/:requirementId/request',
      name: 'revision-request',
      component: () => import('../views/workbench/requirement/RevisionRequestView.vue')
    },
    {
      path: '/err/403',
      name: 'not-found',
      component: () => import('../views/errors/ForbiddenView.vue')
    },
    {
      path: '/err/404',
      name: 'forbidden',
      component: () => import('../views/errors/NotFoundView.vue')
    },
    {
      path: '/err/500',
      name: 'error',
      component: () => import('../views/errors/ErrorView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-match',
      component: () => import('../views/errors/NotFoundView.vue')
    }
  ]
})

router.beforeEach(async (to) => {
  // 检查系统配置是否已加载，如果未加载则加载
  const configStore = useConfigStore()
  const { loaded } = storeToRefs(configStore)
  const { updateConfigs } = configStore
  if (!loaded.value) {
    try {
      const res = await querySystemConfigList([CONFIG_TYPE_SYSTEM])
      updateConfigs(res.queryData)
    } catch (err: any) {
      console.error('获取系统配置失败', err)
      message.error('获取系统配置失败，请刷新页面重试')
    }
  }
  const accountStore = useAccountStore()
  const { user } = storeToRefs(accountStore)
  if (!user.value) {
    // 尝试login
    try {
      const { login } = accountStore
      const res = await fetchAccountProfile()
      await login(res.data)
      return true
    } catch (err: any) {
      console.error('获取用户信息失败', err)
      if (to.name === 'index' || to.name === 'home' || to.name === 'pricing') {
        return true
      } else {
        message.warn('您尚未登录，请先登录或刷新页面后重试')
        return {
          replace: true,
          name: 'index'
        }
      }
    }
  } else {
    return true
  }
})

export default router
