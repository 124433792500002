import type { Organization } from '@/api/org/basic'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useOrgStore = defineStore('org', () => {
  // 暂时把org放入sessionStorage中，避免出现刷新页面后org丢失的情况
  const org = ref<Organization | null>(null)
  const orgList = ref<Organization[] | null>(null)
  const loadOrgFromStorage = (currentOrgId: number) => {
    const sessionOrg = sessionStorage.getItem('org')
    if (sessionOrg) {
      org.value = JSON.parse(sessionOrg)
    } else {
      org.value =
        orgList.value?.find((item) => item.id === currentOrgId) || orgList.value?.[0] || null
    }
  }
  const changeOrg = (currentOrg: Organization) => {
    org.value = currentOrg
    sessionStorage.setItem('org', JSON.stringify(currentOrg))
  }
  const resetOrg = () => {
    org.value = null
    sessionStorage.removeItem('org')
  }
  const setOrgList = (orgs: Organization[]) => {
    orgList.value = orgs
  }
  const resetOrgList = () => {
    orgList.value = null
  }
  return {
    org,
    orgList,
    loadOrgFromStorage,
    changeOrg,
    resetOrg,
    setOrgList,
    resetOrgList
  }
})
