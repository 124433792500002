import request from '@/utils/request'
import dayjs from 'dayjs'
import { ADMIN_HEADER_NAME, MicroSvrAddrs } from '../common/config'
import type { DataResponse, OperationResponse } from '../common/entity'
import { dataListTransformer, singleDataTransformer } from '../common/utils'

export enum UserWorkStatus {
  INITIAL = 'INITIAL', // 初始状态
  WORKING = 'WORKING', // 工作中
  RESTING = 'RESTING', // 休息中
  LEAVE = 'LEAVE' // 请假中
}

export const UserWorkStatusMap = new Map<UserWorkStatus, string>([
  [UserWorkStatus.INITIAL, '初始状态'],
  [UserWorkStatus.WORKING, '工作中'],
  [UserWorkStatus.RESTING, '休息中'],
  [UserWorkStatus.LEAVE, '请假中']
])

export enum UserTaskNature {
  STAND_BY = 'STAND_BY', // 待命
  MEETING = 'MEETING', // 会议
  EVALUATING = 'EVALUATING', // 评估中
  EXECUTING = 'EXECUTING', // 执行中
  TEMPORARY = 'TEMPORARY', // 临时任务
  DISCUSSING = 'DISCUSSING', // 讨论中
  LEARNING_RESEARCHING_AND_CONCEIVING = 'LEARNING_RESEARCHING_AND_CONCEIVING', // 学习研究构思
  OUTSIDE_COMMUNICATING = 'OUTSIDE_COMMUNICATING', // 外部沟通
  INSIDE_COMMUNICATING = 'INSIDE_COMMUNICATING', // 内部沟通
  OTHER = 'OTHER', // 其他
  PERSONAL_TODO = 'PERSONAL_TODO' // 个人待办
}

export const UserTaskNatureMap = new Map<UserTaskNature, string>([
  [UserTaskNature.STAND_BY, '待命中'],
  [UserTaskNature.MEETING, '会议中'],
  [UserTaskNature.EVALUATING, '评估中'],
  [UserTaskNature.EXECUTING, '执行中'],
  [UserTaskNature.TEMPORARY, '临时任务'],
  [UserTaskNature.DISCUSSING, '讨论中'],
  [UserTaskNature.LEARNING_RESEARCHING_AND_CONCEIVING, '学习、研究、构思'],
  [UserTaskNature.OUTSIDE_COMMUNICATING, '外部沟通'],
  [UserTaskNature.INSIDE_COMMUNICATING, '内部沟通'],
  [UserTaskNature.OTHER, '其他'],
  [UserTaskNature.PERSONAL_TODO, '个人待办']
])

export interface User {
  id: number
  name: string
  avatarUrl?: string
  currentOrgId: number
  workStatus: UserWorkStatus
  taskNature: UserTaskNature
  createTime: Date
  updateTime: Date
  deleted: boolean
  deleteTime?: Date
}

const userTransformer = (data: any): User => {
  data.createTime = dayjs.unix(data.createTime).toDate()
  data.updateTime = dayjs.unix(data.updateTime).toDate()
  data.deleteTime = data.deleteTime ? dayjs.unix(data.createTime).toDate() : undefined
  return data as User
}

export const singleUserResponseTransformer = singleDataTransformer(userTransformer)

export const userListResponseTransformer = dataListTransformer(userTransformer)

// 管理员认证请求
export async function adminAuth(user: User, adminToken: string): Promise<OperationResponse<User>> {
  return request.post(`${MicroSvrAddrs.taskania}/auth/admin`, user, {
    headers: { [ADMIN_HEADER_NAME]: `Bearer ${adminToken}` },
    transformResponse: singleDataTransformer(userTransformer)
  })
}

// 微信网页认证接口地址
export const WX_WEB_AUTH_URL = `${MicroSvrAddrs.taskania}/auth/wx-web/authenticate`

// 获取微信网页认证state
export async function fetchWxWebAuthState(): Promise<DataResponse<string>> {
  return request.get(`${MicroSvrAddrs.taskania}/auth/wx-web/state`)
}
