<script setup lang="ts">
import { OrgStatus, type Organization } from '@/api/org/basic'
import { useOrgStore } from '@/stores/org'
import { DownOutlined, LinkOutlined } from '@ant-design/icons-vue'
import type { MenuInfo } from 'ant-design-vue/es/menu/src/interface'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const orgName = ref('您尚未加入任何团队')
const selectedOrg = ref<number[]>([])
// const orgDatas = ref<Organization[]>([])
const emits = defineEmits(['change'])

// const accountStore = useAccountStore()
// const { user } = storeToRefs(accountStore)
const orgStore = useOrgStore()
const { org, orgList } = storeToRefs(orgStore)
const { changeOrg } = orgStore

const doChangeOrg = ({ key }: MenuInfo) => {
  if (key === 'orgManage') {
    return
  }
  selectedOrg.value = [key as number]
  // const newOrg = orgDatas.value.find((org) => org.id === key) as Organization
  const newOrg = orgList.value!.find((org) => org.id === key) as Organization
  orgName.value = newOrg?.name || '您尚未加入任何团队'
  changeOrg(newOrg)
  emits('change', key)
}

const router = useRouter()
// const route = useRoute()
const toOrgPage = () => {
  router.push('/org')
}

watch(
  () => org.value,
  (newOrg) => {
    if (newOrg) {
      orgName.value = newOrg.name
      selectedOrg.value = [newOrg.id]
    } else {
      orgName.value = '您尚未加入任何团队'
      selectedOrg.value = []
    }
  }
)

// const watchHandler = watchEffect(() => {
//   if (orgList.value && orgList.value.length > 0 && user.value && org.value) {
//     // 此时是刚登录后第一次进入页面
//     let orgId: number
//     if (selectedOrg.value.length > 0) {
//       orgId = selectedOrg.value[0]
//     } else if (route.params.orgId) {
//       orgId = Number(route.params.orgId)
//     } else if (route.query.orgId) {
//       orgId = Number(route.query.orgId)
//     } else {
//       orgId = user.value!.currentOrgId
//     }
//     const currOrg = orgList.value!.find((org) => org.id === orgId) || orgList.value![0]
//     selectedOrg.value = [currOrg.id]
//     orgName.value = currOrg.name
//     changeOrg(currOrg)
//     emits('change', orgId)
//     watchHandler()
//   }
// })
</script>

<template>
  <a-dropdown>
    <a-button>
      <a-flex justify="center" :align="'center'">
        <span
          style="display: inline-block; max-width: 140px; padding-right: 10px"
          class="ellipsis"
          >{{ orgName }}</span
        >
        <DownOutlined />
      </a-flex>
    </a-button>
    <template #overlay>
      <a-menu @click="doChangeOrg" :selectedKeys="selectedOrg">
        <a-menu-item
          v-for="org in orgList!"
          :key="org.id"
          :disabled="org.status === OrgStatus.DISSOLVED"
          >{{ org.name }}</a-menu-item
        >
        <a-menu-divider v-if="orgList!.length > 0"></a-menu-divider>
        <a-menu-item key="orgManage" style="text-align: center; color: #1677ff">
          <a @click="toOrgPage"> 团队管理 <LinkOutlined /></a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<style scoped lang="less"></style>
