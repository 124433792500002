import request from '@/utils/request'
import dayjs from 'dayjs'
import { MicroSvrAddrs } from '../common/config'
import type { DataResponse, OperationResponse, QueryResponse } from '../common/entity'
import { dataListTransformer, singleDataTransformer } from '../common/utils'

export interface UserTodo {
  id: string
  userId: number
  title: string
  description: string
  completed: boolean
  createTime: Date
  startTime?: Date
  endTime?: Date
  actualTimeInHours?: number
}

const todoTransformer = (data: any): UserTodo => {
  data.createTime = dayjs.unix(data.createTime).toDate()
  data.startTime = data.startTime ? dayjs.unix(data.startTime).toDate() : undefined
  data.endTime = data.endTime ? dayjs.unix(data.endTime).toDate() : undefined
  return data as UserTodo
}

const singleTodoResponseTransformer = singleDataTransformer(todoTransformer)

const todoListResponseTransformer = dataListTransformer(todoTransformer)

// 获取用户待办列表
export async function fetchUserTodoList(): Promise<QueryResponse<UserTodo>> {
  return request.get(`${MicroSvrAddrs.taskania}/user/todo`, {
    transformResponse: todoListResponseTransformer
  })
}

// 获取用户待办详情
export async function fetchUserTodoDetail(id: string): Promise<DataResponse<UserTodo>> {
  return request.get(`${MicroSvrAddrs.taskania}/user/todo/${id}`, {
    transformResponse: singleTodoResponseTransformer
  })
}

export interface UserTodoReq {
  title: string
  description: string
}

// 创建用户待办
export async function createUserTodo(req: UserTodoReq): Promise<OperationResponse<UserTodo>> {
  return request.post(`${MicroSvrAddrs.taskania}/user/todo`, req)
}

// 更新用户待办
export async function updateUserTodo(
  id: string,
  req: UserTodoReq
): Promise<OperationResponse<UserTodo>> {
  return request.put(`${MicroSvrAddrs.taskania}/user/todo/${id}`, req)
}

export interface UserTodoFinishReq {
  startTime: Date
  endTime: Date
  actualTimeInHours: number
}

// 完成用户待办
export async function finishUserTodo(
  id: string,
  req: UserTodoFinishReq
): Promise<OperationResponse<UserTodo>> {
  return request.put(`${MicroSvrAddrs.taskania}/user/todo/${id}/finish`, req)
}

// 删除用户待办
export async function deleteUserTodo(id: string): Promise<OperationResponse<UserTodo>> {
  return request.delete(`${MicroSvrAddrs.taskania}/user/todo/${id}`)
}
