import type { User, UserWorkStatus } from '@/api/auth'
import { establishWebSocket } from '@/api/notice/ws'
import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useOrgStore } from './org'
import { fetchOrgList } from '@/api/org/basic'

export const useAccountStore = defineStore('user', () => {
  const orgStore = useOrgStore()
  const { org, orgList } = storeToRefs(orgStore)
  const { loadOrgFromStorage, resetOrg, setOrgList, resetOrgList } = orgStore
  const user = ref<User | null>(null)
  const noticeWs = ref<WebSocket | null>(null)
  const login = async (currentUser: User) => {
    user.value = currentUser
    noticeWs.value = establishWebSocket()
    // 设定团队列表
    if (!orgList.value) {
      const orgResp = await fetchOrgList()
      setOrgList(orgResp.queryData)
      // 从store中读取org
      if (!org.value) {
        loadOrgFromStorage(currentUser.currentOrgId)
      }
    }
  }
  const logout = () => {
    noticeWs.value?.close()
    user.value = null
    resetOrg()
    resetOrgList()
  }
  const updateUserInfo = (newInfo: User) => {
    if (!user.value) {
      return
    }
    user.value.name = newInfo.name
    user.value.avatarUrl = newInfo.avatarUrl
  }
  const updateWorkStatus = (status: UserWorkStatus) => {
    if (!user.value) {
      return
    }
    user.value.workStatus = status
  }
  const updateCurrentOrg = (orgId: number) => {
    if (!user.value) {
      return
    }
    user.value.currentOrgId = orgId
  }
  return {
    user,
    noticeWs,
    login,
    logout,
    updateUserInfo,
    updateWorkStatus,
    updateCurrentOrg
  }
})

export const useUserMapStore = defineStore('userMap', () => {
  const userMap = ref<Map<number, User>>(new Map())
  const updateUserMapEntry = (users: User[]) => {
    users.forEach((user) => {
      userMap.value.set(user.id, user)
    })
  }
  return {
    userMap,
    updateUserMapEntry
  }
})
