<script setup lang="ts">
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { ref } from 'vue'
import { RouterLink } from 'vue-router'
import BasicLayout from './layouts/BasicLayout.vue'
import { initEchartsUse } from './utils/echarts'
dayjs.locale('zh-cn')

// 判断进入页面的客户端类型
const agent = ref<string>('PC')
if (location.pathname.includes('wx.html')) {
  agent.value = 'WX'
} else if (location.pathname.includes('mobile.html')) {
  agent.value = 'MOBILE'
}

// 初始化echarts
initEchartsUse()
</script>

<template>
  <!-- <div id="taskania-main"> -->
  <header v-if="agent != 'PC'">
    <img alt="Vue logo" class="logo" src="@/assets/img/logo.svg" width="125" height="125" />

    <div class="wrapper">
      <nav>
        <RouterLink to="/">Home</RouterLink>
        <RouterLink to="/about">About</RouterLink>
      </nav>
    </div>
  </header>

  <a-config-provider :locale="zhCN" :theme="{ token: { colorPrimary: '#7cbb00' } }" v-else>
    <basic-layout />
  </a-config-provider>
  <!-- </div> -->
</template>

<style lang="less" scoped>
#taskania-main {
  height: 100%;
  width: 100%;
}
</style>
