import request from '@/utils/request'
import { singleUserResponseTransformer, type User } from '../auth'
import { MicroSvrAddrs } from '../common/config'
import type { BaseResponse, OperationResponse } from '../common/entity'

// 网页端登出
export async function accountLogout(): Promise<BaseResponse> {
  return request.get(`${MicroSvrAddrs.taskania}/account/logout`)
}

// 获取账号信息
export async function fetchAccountProfile(): Promise<OperationResponse<User>> {
  // 这个接口直接使用fetch来进行，避免因为未登录时使用axios而被响应拦截器处理
  return fetch(
    `${import.meta.env.VITE_COMMON_API_BASE_URL}${MicroSvrAddrs.taskania}/account/profile`,
    {
      method: 'GET',
      credentials: 'include'
    }
  ).then(
    (res) => {
      if (res.status === 401) {
        return Promise.reject(res.json())
      } else {
        return Promise.resolve(
          res.text().then((data) => singleUserResponseTransformer(data) as OperationResponse<User>)
        )
      }
    },
    (err) => {
      return Promise.reject(err)
    }
  )
  // return request.get(`${MicroSvrAddrs.taskania}/account/profile`, {
  //   transformResponse: singleUserResponseTransformer
  // })
}

interface AccountProfileReq {
  name: string
  avatarUrl?: string
}

// 更新账号信息
export async function updateAccountProfile(
  req: AccountProfileReq
): Promise<OperationResponse<number>> {
  return request.put(`${MicroSvrAddrs.taskania}/account/profile`, req)
}
