import dayjs from 'dayjs'
import type { DataResponse, OperationResponse, QueryResponse } from '../common/entity'
import request from '@/utils/request'
import { MicroSvrAddrs } from '../common/config'
import { dataListTransformer, singleDataTransformer } from '../common/utils'

export enum NoticeWay {
  SEND_TO_ME = 1, // 发送给我的，我收到的
  SEND_TO_OTHERS = 2 // 我发送的，别人收到的
}

export function noticeWayVal(waynum: number): NoticeWay | undefined {
  switch (waynum) {
    case 1:
      return NoticeWay.SEND_TO_ME
    case 2:
      return NoticeWay.SEND_TO_OTHERS
    default:
      return undefined
  }
}

export enum NoticeType {
  SYSTEM = '1', // 系统通知
  USER = '2' // 用户通知（个人通知）
}

export function noticeTypeVal(type: string): NoticeType | undefined {
  switch (type) {
    case '1':
      return NoticeType.SYSTEM
    case '2':
      return NoticeType.USER
    default:
      return undefined
  }
}

enum NoticeStatus {
  UNHANDLED = 1, // 未处理
  HANDLED = 2 // 已处理
}

export function noticeStatusVal(status: string): NoticeStatus | undefined {
  switch (status) {
    case '1':
      return NoticeStatus.UNHANDLED
    case '2':
      return NoticeStatus.HANDLED
    default:
      return undefined
  }
}

export enum MessageStatus {
  SENDING = 'SENDING', // 发送中
  SENT = 'SENT', // 已发送
  SEND_FAILED = 'SEND_FAILED', // 发送失败
  DELIVERED = 'DELIVERED', // 已送达
  PROCESSED = 'PROCESSED' // 已处理
}

export const MessageStatusMap = new Map<MessageStatus, string>([
  [MessageStatus.SENDING, '发送中'],
  [MessageStatus.SENT, '已发送'],
  [MessageStatus.SEND_FAILED, '发送失败'],
  [MessageStatus.DELIVERED, '已送达'],
  [MessageStatus.PROCESSED, '已处理']
])

export enum MessageType {
  SINGLE_SEND_NO_REPLY = 'SINGLE_SEND_NO_REPLY', // 单发不需要回复
  SINGLE_SEND_NEED_REPLY = 'SINGLE_SEND_NEED_REPLY', // 单发需要回复
  GROUP_SEND_NO_REPLY = 'GROUP_SEND_NO_REPLY', // 群发不需要回复
  GROUP_SEND_NEED_REPLY = 'GROUP_SEND_NEED_REPLY', // 群发需要回复
  SPECIAL = 'SPECIAL' // 特殊消息
}

export enum MessageOperateType {
  RECEIVED = 'RECEIVED', // 收到
  READ = 'READ', // 已读
  REPLY = 'REPLY', // 回复
  CLOSE = 'CLOSE' // 关闭
}

export interface Notice {
  id: string
  parentId?: string
  fromUserId?: number
  toUserId: number
  content: string
  status: MessageStatus
  type: MessageType
  createTime: Date
  receiveTime?: Date
  sourceId?: string
  msgType: NoticeType
}

export const noticeTransformer = (data: any): Notice => {
  data.createTime = dayjs.unix(data.createTime).toDate()
  data.receiveTime = data.receiveTime ? dayjs.unix(data.receiveTime).toDate() : undefined
  return data as Notice
}

export interface NoticeDetail extends Notice {
  fromUserName: string
}

const singleNoticeResponseTransformer = singleDataTransformer(noticeTransformer)

const noticeListResponseTransformer = dataListTransformer(noticeTransformer)

// 查询通知列表
export async function fetchNoticeList(
  pageSize: number,
  pageNo: number,
  way?: NoticeWay,
  msgType?: NoticeType,
  unhandled?: boolean
): Promise<QueryResponse<Notice>> {
  return request.get(`${MicroSvrAddrs.taskania}/notice`, {
    params: { way, msgType, unhandled, pageSize, pageNo },
    transformResponse: noticeListResponseTransformer
  })
}

// 查看通知详情
export async function fetchNoticeDetail(
  id: string,
  msgType: NoticeType
): Promise<DataResponse<Notice>> {
  return request.get(`${MicroSvrAddrs.taskania}/notice/${msgType}/${id}`, {
    transformResponse: singleNoticeResponseTransformer
  })
}

// 处理/回复通知
export async function handleOrReplyNotice(
  id: string,
  msgType: NoticeType,
  content?: string
): Promise<OperationResponse<string>> {
  return request.put(`${MicroSvrAddrs.taskania}/notice/${msgType}/${id}`, { content })
}

// 全部标记为已读
export async function markAllNoticeRead(type?: NoticeType): Promise<OperationResponse<boolean>> {
  return request.put(`${MicroSvrAddrs.taskania}/notice/read-all`, { data: { type } })
}
