// 各个微服务地址
export const MicroSvrAddrs = {
  taskania: import.meta.env.VITE_TASKANIA_API_BASE_URL
}

export const ADMIN_HEADER_NAME = 'X-System-Admin'

export const SYSTEM_CONF_TYPE = 'system'
export const INDEX_CONF_ITEM = 'index'
export const PRICIING_CONF_ITEM = 'pricing'
export const GUIDE_CONF_ITEM = 'guide'
