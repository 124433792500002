<script setup lang="ts">
import { fetchUserWorkRecord, type UserWorkRecord } from '@/api/user'
import { HistoryOutlined, LinkOutlined } from '@ant-design/icons-vue'
import dayjs from 'dayjs'
import { onMounted, ref, watch } from 'vue'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const visible = ref(false)
const loading = ref(false)
const recordDatas = ref<UserWorkRecord[]>([])

function refreshList() {
  loading.value = true
  const now = dayjs()
  //   const startTime = dayjs('2019-01-25').toDate()
  const startTime = now.subtract(1, 'day').toDate()
  const endTime = now.toDate()
  fetchUserWorkRecord(startTime, endTime).then(
    (res) => {
      recordDatas.value = res.queryData
      loading.value = false
    },
    (err) => {
      message.error(err.response?.data.message || '查询您的工作记录失败')
      loading.value = false
    }
  )
}
onMounted(refreshList)

watch(visible, (newValue) => {
  if (newValue) {
    refreshList()
  }
})

const toRecordPage = () => {
  visible.value = false
  router.push('/record')
}

function formatShortDate(val: Date): string {
  const time = dayjs(val)
  const now = dayjs()
  if (time.isSame(now, 'day')) {
    if (time.diff(now, 'minute') >= -1) {
      return '刚刚'
    } else {
      return time.format('H:mm')
    }
  } else {
    const diffDay = time.diff(now, 'day')
    if (diffDay === -1) {
      return time.format('昨天H:mm')
    } else {
      return time.format('D日H:mm')
    }
  }
}
</script>

<template>
  <a-popover v-model:open="visible" trigger="hover" placement="bottomRight">
    <template #title>
      <a-flex :justify="'space-between'" :align="'center'">
        <span>工作记录</span>
        <a @click="toRecordPage" style="font-weight: normal">查看更多<LinkOutlined /></a>
      </a-flex>
    </template>
    <template #content>
      <a-list
        :data-source="recordDatas"
        class="scroll-popover-content"
        style="width: 415px; max-height: 450px; overflow-y: auto"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <a-skeleton
              :loading="loading"
              active
              :paragraph="{ rows: 1, width: 375 }"
              :title="false"
            >
              <a-flex :justify="'space-between'" :align="'center'">
                <div class="ellipsis" style="width: 240px">{{ item.taskName }}</div>
                <div class="ellipsis" style="width: 135px; text-align: right; opacity: 0.25">
                  {{ formatShortDate(item.startTime) }}-{{ formatShortDate(item.endTime) }}
                </div>
              </a-flex>
            </a-skeleton>
          </a-list-item>
        </template>
      </a-list>
    </template>
    <a-avatar shape="square" style="background-color: #783bd2">
      <template #icon>
        <HistoryOutlined />
      </template>
    </a-avatar>
  </a-popover>
</template>

<style scoped lang="less"></style>
