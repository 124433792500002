import './app.less'
import {
  Button,
  Divider,
  Flex,
  Row,
  Col,
  Layout,
  Space,
  Breadcrumb,
  Dropdown,
  Menu,
  PageHeader,
  Pagination,
  Steps,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TimePicker,
  Transfer,
  TreeSelect,
  Avatar,
  Badge,
  Card,
  Carousel,
  Collapse,
  Comment,
  Descriptions,
  Empty,
  Image,
  List,
  Popover,
  QRCode,
  Statistic,
  Table,
  Tabs,
  Tag,
  Timeline,
  Tooltip,
  Tree,
  Alert,
  Drawer,
  Modal,
  Popconfirm,
  Result,
  Skeleton,
  Spin,
  FloatButton,
  ConfigProvider
} from 'ant-design-vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'

import { setGlobalOptions } from 'vue-request'

if (typeof (window as any).global === 'undefined') {
  ;(window as any).global = window
}

declare global {
  interface Window {
    WxLogin: any // 微信登录组件
  }
}

const app = createApp(App)

app
  .use(Button)
  .use(Divider)
  .use(Flex)
  .use(Row)
  .use(Col)
  .use(Layout)
  .use(Space)
  .use(Breadcrumb)
  .use(Dropdown)
  .use(Menu)
  .use(PageHeader)
  .use(Pagination)
  .use(Steps)
  .use(Checkbox)
  .use(DatePicker)
  .use(Form)
  .use(Input)
  .use(InputNumber)
  .use(Radio)
  .use(Select)
  .use(Switch)
  .use(TimePicker)
  .use(Transfer)
  .use(TreeSelect)
  .use(Avatar)
  .use(Badge)
  .use(Card)
  .use(Carousel)
  .use(Collapse)
  .use(Comment)
  .use(Descriptions)
  .use(Empty)
  .use(Image)
  .use(List)
  .use(Popover)
  .use(QRCode)
  .use(Statistic)
  .use(Table)
  .use(Tabs)
  .use(Tag)
  .use(Timeline)
  .use(Tooltip)
  .use(Tree)
  .use(Alert)
  .use(Drawer)
  .use(Modal)
  .use(Popconfirm)
  .use(Result)
  .use(Skeleton)
  .use(Spin)
  .use(FloatButton)
  .use(ConfigProvider)

app.use(createPinia())
app.use(router)

dayjs.extend(relativeTime)
dayjs.locale('zh-cn')

setGlobalOptions({
  loadingDelay: 50,
  loadingKeep: 100
  // debounceInterval: 0
})

app.mount('#app')
