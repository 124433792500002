<script setup lang="ts">
import {
  NoticeType,
  NoticeWay,
  fetchNoticeList,
  handleOrReplyNotice,
  noticeTransformer,
  type NoticeDetail
} from '@/api/notice'
import { ReplyMsgType, type WsReplyData } from '@/api/notice/ws'
import { fetchUserAndUpdateEntry } from '@/api/user'
import { useAccountStore, useUserMapStore } from '@/stores/user'
import {
  CheckOutlined,
  LinkOutlined,
  MessageOutlined,
  NotificationOutlined
} from '@ant-design/icons-vue'
import { message, notification } from 'ant-design-vue'
import dayjs from 'dayjs'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const visible = ref(false)
const loading = ref(false)
const noticeDatas = ref<NoticeDetail[]>([])

const accountStore = useAccountStore()
const { noticeWs } = storeToRefs(accountStore)
const userMapStore = useUserMapStore()
const { userMap } = storeToRefs(userMapStore)
const { updateUserMapEntry } = userMapStore

const showNoticeSwitch = computed(() => Notification.permission === 'default')
const turnOnNotification = () => {
  Notification.requestPermission()
}

async function refreshList() {
  loading.value = true
  try {
    const noticeResp = await fetchNoticeList(100, 1, NoticeWay.SEND_TO_ME, undefined, true)
    const data = noticeResp.queryData
    // const data = [
    //   {
    //     id: '1',
    //     fromUserId: -1,
    //     toUserId: 0,
    //     content: '您有一个任务消息待处理',
    //     status: NoticeStatus.UNHANDLED,
    //     createTime: new Date(),
    //     msgType: '1'
    //   },
    //   {
    //     id: '2',
    //     toUserId: 0,
    //     content: '您有一个系统通知待处理',
    //     status: NoticeStatus.UNHANDLED,
    //     createTime: new Date(),
    //     msgType: '1'
    //   }
    // ]
    const userIds = new Set<number>()
    const noticeList = data.map((n) => {
      const detail = n as unknown as NoticeDetail
      if (typeof detail.fromUserId !== 'undefined') {
        const fromUser = userMap.value.get(detail.fromUserId)
        if (fromUser) {
          detail.fromUserName = fromUser.name
        } else {
          userIds.add(detail.fromUserId)
        }
      } else {
        detail.fromUserName = '系统'
      }
      return detail
    })
    // 查询发送者用户名
    if (userIds.size > 0) {
      fetchUserAndUpdateEntry([...userIds], updateUserMapEntry, () => {
        noticeList.forEach((n) => {
          if (!n.fromUserName && typeof n.fromUserId !== 'undefined') {
            n.fromUserName = userMap.value.get(n.fromUserId)?.name || '未知用户'
          }
        })
      })
    }
    noticeDatas.value = noticeList
    loading.value = false
  } catch (err: any) {
    message.error(err.response?.data.message || '查询您的通知列表失败')
    loading.value = false
  }
}

const notificationAlerted = ref(false)
onMounted(() => {
  refreshList()
  if (noticeWs.value) {
    noticeWs.value.onmessage = (event: MessageEvent<string>) => {
      const msgData = JSON.parse(event.data)
      // 目前仅处理送达响应和通知信息
      if ('code' in msgData) {
        // 此时包括送达响应、已读响应和回复响应，但暂时不需要任何处理
      } else {
        // 此时发送的是通知信息，加入列表，并回复收到
        const notice = noticeTransformer(msgData)
        const detail = notice as NoticeDetail
        if (typeof detail.fromUserId !== 'undefined') {
          const fromUser = userMap.value.get(detail.fromUserId)
          if (fromUser) {
            detail.fromUserName = fromUser.name
          } else {
            fetchUserAndUpdateEntry([detail.fromUserId], updateUserMapEntry, () => {
              detail.fromUserName = userMap.value.get(detail.fromUserId!)?.name || '未知用户'
            })
          }
        } else {
          detail.fromUserName = '系统'
        }
        noticeDatas.value.unshift(detail)
        noticeWs.value?.send(
          JSON.stringify({
            id: detail.id,
            type: detail.msgType,
            msgType: ReplyMsgType.RECEIVED
          } as WsReplyData)
        )
        // 提示用户有新的通知
        if (Notification.permission === 'granted') {
          // 用户已经允许发送通知，则使用Notification API来通知
          new Notification(
            `您有新的${typeof detail.fromUserId !== 'undefined' ? '' : '系统'}消息`,
            {
              body: detail.content
            }
          )
        } else if (Notification.permission === 'denied') {
          // 用户已经拒绝发送通知，则使用Antd的notification来通知
          notification.info({
            message: `您有新的${typeof detail.fromUserId !== 'undefined' ? '' : '系统'}消息`,
            description: detail.content,
            placement: 'bottomRight'
          })
        } else {
          // 用户还未选择是否允许发送通知
          // 先使用Antd则的notification立刻通知
          notification.info({
            message: `您有新的${typeof detail.fromUserId !== 'undefined' ? '' : '系统'}消息`,
            description: detail.content,
            placement: 'topRight',
            duration: 0,
            onClose: () => {
              if (Notification.permission === 'granted') {
                message.info('您已允许通知，下次将使用浏览器来进行通知')
              } else if (Notification.permission === 'default') {
                message.info('您仍然可以悬停在通知按钮上点击“打开浏览器通知”来开启浏览器通知')
              }
            }
          })
          // 然后尝试让用户启用Notification API
          if (notificationAlerted.value) {
            return
          }
          Notification.requestPermission()
          notification.warn({
            message: '消息提示不明显？',
            description: '请在地址栏左下方弹出的提示中允许通知，可以更及时、更明显地得到提示',
            placement: 'bottomRight'
          })
          notificationAlerted.value = true
        }
      }
    }
  }
})

const toNoticePage = () => {
  visible.value = false
  router.push('/message')
}

const hoverItemId = ref<Set<string>>(new Set<string>())
watch(visible, (newValue) => {
  if (!newValue) {
    hoverItemId.value.clear()
  }
})

function markRead(notice: NoticeDetail) {
  const msgType = notice.msgType === '1' ? NoticeType.SYSTEM : NoticeType.USER
  handleOrReplyNotice(notice.id, msgType).then(
    () => {
      message.success('消息标记为已读成功')
      refreshList()
    },
    (err) => {
      message.error(err.response?.data.message || '消息标记为已读失败')
    }
  )
}
</script>

<template>
  <a-popover v-model:open="visible" trigger="hover" placement="bottomRight">
    <template #title>
      <a-flex :justify="'space-between'" :align="'center'">
        <span>通知&消息</span>
        <a-space size="large" style="font-weight: normal">
          <a-tooltip v-if="showNoticeSwitch">
            <template #title>在地址栏左下方弹出的提示中允许通知，可以更及时地得到提示</template>
            <a @click="turnOnNotification">打开浏览器通知<NotificationOutlined /></a>
          </a-tooltip>
          <a @click="toNoticePage" style="font-weight: normal">查看全部&更多操作<LinkOutlined /></a>
        </a-space>
      </a-flex>
    </template>
    <template #content>
      <a-list
        :data-source="noticeDatas"
        class="scroll-popover-content"
        style="width: 400px; max-height: 450px; overflow-y: auto"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <a-skeleton :loading="loading" active :paragraph="{ rows: 1, width: 360 }">
              <a-comment
                :content="item.content"
                :datetime="dayjs(item.createTime).fromNow()"
                style="width: 100%; position: relative"
                @mouseover="hoverItemId.add(item.id)"
                @mouseleave="hoverItemId.delete(item.id)"
              >
                <a @click="markRead(item)" class="mark-read" v-show="hoverItemId.has(item.id)">
                  <CheckOutlined /> 标记为已读
                </a>
                <template #author>
                  <span v-if="item.fromUserName === '系统'" style="color: #108ee9">系统</span>
                  <span v-else>{{ item.fromUserName }}</span>
                </template>
                <!-- <template #actions>
                  <a @click="markRead" style="font-size: 12px"><CheckOutlined /> 标记为已读</a>
                </template> -->
              </a-comment>
            </a-skeleton>
          </a-list-item>
        </template>
      </a-list>
    </template>
    <a-badge :count="noticeDatas.length">
      <a-avatar shape="square" style="background-color: #ffbb00">
        <template #icon>
          <MessageOutlined />
        </template>
      </a-avatar>
    </a-badge>
  </a-popover>
</template>

<style scoped lang="less">
.mark-read {
  display: block;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  z-index: 1;
}
</style>
