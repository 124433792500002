import request from '@/utils/request'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import { UserWorkStatus, userListResponseTransformer, type User } from '../auth'
import { MicroSvrAddrs } from '../common/config'
import type { BaseResponse, QueryResponse } from '../common/entity'
import { formatOffsetISO } from '../common/utils'
import type { TaskCategory } from '../project/task'

// 批量获取用户信息
export async function fetchUserInfoList(ids: number[]): Promise<QueryResponse<User>> {
  return request.get(`${MicroSvrAddrs.taskania}/user`, {
    params: { ids: ids.join(',') },
    transformResponse: userListResponseTransformer
  })
}

// 批量获取用户信息并更新用户信息缓存
export async function fetchUserAndUpdateEntry(
  ids: number[],
  updateUserMapEntry: (users: User[]) => void,
  callback?: () => void
): Promise<boolean> {
  if (ids.length === 0) {
    return true
  }
  try {
    const userResp = await fetchUserInfoList(ids)
    updateUserMapEntry(userResp.queryData)
    if (callback) {
      callback()
    }
    return true
  } catch (e) {
    message.error('获取用户信息失败')
    return false
  }
}

// 切换团队
export async function switchOrg(orgId: number): Promise<BaseResponse> {
  return request.put(`${MicroSvrAddrs.taskania}/user/switch/${orgId}`)
}

// 设置工作状态
export async function setWorkStatus(orgId: number, status: UserWorkStatus): Promise<BaseResponse> {
  return request.put(`${MicroSvrAddrs.taskania}/user/status/${orgId}/${status}`)
}

export interface UserWorkRecord {
  task: boolean
  taskNature: TaskCategory
  taskOrTodoId: string
  taskName: string
  startTime: Date
  endTime: Date
}

const workRecordListResponseTransformer = (rawStr: string): QueryResponse<UserWorkRecord> => {
  const data = JSON.parse(rawStr) as QueryResponse<any>
  data.queryData = data.queryData.map((item: any) => {
    item.startTime = dayjs.unix(item.startTime).toDate()
    item.endTime = dayjs.unix(item.endTime).toDate()
    return item as UserWorkRecord
  })
  return data as QueryResponse<UserWorkRecord>
}

// 获取用户工作记录
export async function fetchUserWorkRecord(
  startTime: Date,
  endTime?: Date
): Promise<QueryResponse<UserWorkRecord>> {
  return request.get(`${MicroSvrAddrs.taskania}/user/record`, {
    params: {
      startTime: formatOffsetISO(startTime),
      endTime: endTime ? formatOffsetISO(endTime) : undefined
    },
    transformResponse: workRecordListResponseTransformer
  })
}
