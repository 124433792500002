// 公共方法
import type {
  BaseResponse,
  DataResponse,
  OperationResponse,
  QueryResponse,
  TreeDataInter,
  TreeItemConfig
} from '@/api/common/entity'
import _ from 'lodash'
import dayjs from 'dayjs'

// json字符串转换为单个数据响应实体
export function singleDataTransformer<T>(
  transformer: (data: any) => T
): (rawStr: string) => OperationResponse<T> | DataResponse<T> | BaseResponse {
  return (rawStr: string) => {
    const data = JSON.parse(rawStr)
    if (!('data' in data) || data.data === null) {
      return data as BaseResponse
    } else {
      data.data = transformer(data.data)
      return data as OperationResponse<T> | DataResponse<T>
    }
  }
}

// json字符串转换为单个数据响应实体
export function dataListTransformer<T>(
  transformer: (data: any) => T
): (rawStr: string) => QueryResponse<T> | BaseResponse {
  return (rawStr: string) => {
    const data = JSON.parse(rawStr)
    if (!('queryData' in data)) {
      return data as BaseResponse
    } else {
      data.queryData = data.queryData.map((item: any) => transformer(item))
      return data as OperationResponse<T> | DataResponse<T>
    }
  }
}

export function formatOffsetISO(date: Date): string {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ')
}

// 将TreeDataInter数据转换为原始数据的列表
export function treeDataToRawDataList<T>(treeData: TreeDataInter<T>): T[] {
  const rawDataList: T[] = []
  if (treeData && treeData.rawData) {
    rawDataList.push(treeData.rawData)
    if (treeData.children) {
      treeData.children.forEach((item) => {
        rawDataList.push(...treeDataToRawDataList(item))
      })
    }
  }
  return rawDataList
}

// 将原始数据的列表转换为TreeDataInter数据
export function rawDataListToTreeData<T>(
  rawDataList: T[], // 原始数据列表
  valueProp: keyof T, // 原始数据中表示value的属性名
  labelProp: keyof T, // 原始数据中表示label的属性名
  pIdProp: keyof T, // 原始数据中表示父节点value的属性名
  treeItemConfig?: TreeItemConfig<T> // tree的配置项
): TreeDataInter<T>[] {
  const treeDataList: TreeDataInter<T>[] = []
  if (!rawDataList || rawDataList.length === 0 || !pIdProp) {
    return treeDataList
  }
  const tempList: TreeDataInter<T>[] = rawDataList.map((item) => {
    return {
      value: String(item[valueProp]),
      label: item[labelProp] as string,
      pId: String(item[pIdProp]),
      rawData: item
    }
  })
  const hasParents = [] as boolean[]
  // 遍历tempList，将每个元素添加到其父节点的children中
  tempList.forEach((item) => {
    const pId = item.pId
    if (pId) {
      const parent = tempList.find((tempItem) => tempItem.value === pId)
      if (parent) {
        if (!parent.children) {
          parent.children = []
        }
        parent.children.push(item)
        hasParents.push(true)
        return
      }
    }
    hasParents.push(false)
  })
  // 遍历tempList，将没有父节点的元素作为根节点
  tempList.forEach((item, index) => {
    if (!hasParents[index]) {
      treeDataList.push(item)
    }
  })
  // 设置tree的配置项
  setTreeItemConfig(treeDataList, treeItemConfig)
  return treeDataList
}

// 设置tree的配置项
function setTreeItemConfig<T>(treeData: TreeDataInter<T>[], treeItemConfig?: TreeItemConfig<T>) {
  if (!treeData || treeData.length == 0 || !treeItemConfig) {
    return
  }
  treeData.forEach((item) => {
    if (item.children) {
      const subConf = _.cloneDeep(treeItemConfig)
      if (subConf.disableConfig && subConf.disableConfig.root) {
        subConf.disableConfig.root = subConf.disableConfig.stem
      }
      if (subConf.chcekboxConfig && subConf.chcekboxConfig.root) {
        subConf.chcekboxConfig.root = subConf.chcekboxConfig.stem
      }
      if (subConf.selectConfig && subConf.selectConfig.root) {
        subConf.selectConfig.root = subConf.selectConfig.stem
      }
      if (subConf.iconConfig && subConf.iconConfig.root) {
        subConf.iconConfig.root = subConf.iconConfig.stem
      }
      setTreeItemConfig(item.children, subConf)
    }
    if (treeItemConfig.disableConfig) {
      if (treeItemConfig.disableConfig.func) {
        item.disabled = treeItemConfig.disableConfig.func(item)
      } else {
        if (item.children) {
          item.disabled = treeItemConfig.disableConfig.stem
        } else {
          item.disabled = treeItemConfig.disableConfig.leaf
        }
      }
    }
    if (treeItemConfig.chcekboxConfig) {
      if (treeItemConfig.chcekboxConfig.func) {
        item.disableCheckbox = !treeItemConfig.chcekboxConfig.func(item)
      } else {
        if (item.children) {
          item.disableCheckbox = !treeItemConfig.chcekboxConfig.stem
        } else {
          item.disableCheckbox = !treeItemConfig.chcekboxConfig.leaf
        }
      }
    }
    if (treeItemConfig.selectConfig) {
      if (treeItemConfig.selectConfig.func) {
        item.selectable = treeItemConfig.selectConfig.func(item)
      } else {
        if (item.children) {
          item.selectable = treeItemConfig.selectConfig.stem
        } else {
          item.selectable = treeItemConfig.selectConfig.leaf
        }
      }
    }
    if (treeItemConfig.iconConfig) {
      if (treeItemConfig.iconConfig.func) {
        item.slots = { icon: treeItemConfig.iconConfig.func(item) }
      } else {
        if (item.children && treeItemConfig.iconConfig.stem) {
          item.slots = { icon: treeItemConfig.iconConfig.stem }
        } else if (!item.children && treeItemConfig.iconConfig.leaf) {
          item.slots = { icon: treeItemConfig.iconConfig.leaf }
        }
      }
    }
  })
}
