<script setup lang="ts">
import { accountLogout, updateAccountProfile } from '@/api/account'
import { UserWorkStatus, type User } from '@/api/auth'
import { setWorkStatus } from '@/api/user'
import WechatWebLogin from '@/components/account/WechatWebLogin.vue'
import { useOrgStore } from '@/stores/org'
import { useAccountStore } from '@/stores/user'
import {
  LogoutOutlined,
  QuestionCircleOutlined,
  SwapOutlined,
  UserOutlined
} from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const accountStore = useAccountStore()
const { user } = storeToRefs(accountStore)
const { logout, updateUserInfo, updateWorkStatus } = accountStore
const orgStore = useOrgStore()
const { org } = storeToRefs(orgStore)

const visible = ref(false)
const showMaintain = ref(false)
const showModal = ref(false)
const editLoading = ref(false)

const accountInfo = ref({ name: '' })
const accountRules = ref({
  name: [
    { required: true, message: '必须输入用户名', trigger: 'change' },
    { min: 1, max: 20, message: '用户名长度必须在1-20之间', trigger: 'blur' }
  ]
})

const maintainUserInfo = () => {
  visible.value = false
  showMaintain.value = false
  showModal.value = true
  accountInfo.value.name = user.value?.name || ''
}

const changeLeaveStatus = (checked: boolean) => {
  const workStatus = checked ? UserWorkStatus.LEAVE : UserWorkStatus.WORKING
  setWorkStatus(org.value?.id || 0, workStatus).then(
    () => {
      updateWorkStatus(workStatus)
      message.success(checked ? '请假成功' : '销假成功')
    },
    (err) => {
      message.error(err.response?.data.message || '修改失败')
    }
  )
}

const userLogout = () => {
  accountLogout().then(
    () => {
      // 返回首页
      logout()
      message.success('您已成功退出登录')
      router.push('/')
    },
    (err) => {
      message.error(err.response?.data.message || '退出登录失败')
    }
  )
}

// onMounted(() => {
//   fetchAccountProfile().then(
//     (res) => {
//       login(res.data)
//     },
//     (err) => {
//       err.then(
//         (res: BaseResponse) => message.warn(res.message),
//         () => message.warn('获取用户信息失败')
//       )
//     }
//   )
// })

const doMaintain = () => {
  editLoading.value = true
  updateAccountProfile({ name: accountInfo.value.name, avatarUrl: '' }).then(
    () => {
      editLoading.value = false
      showModal.value = false
      updateUserInfo({ ...user.value, name: accountInfo.value.name } as User)
      message.success('维护个人信息成功')
    },
    (err) => {
      editLoading.value = false
      message.error(err.response?.data.message || '维护个人信息失败')
    }
  )
}
</script>

<template>
  <a-popover v-model:open="visible" trigger="hover" placement="bottomRight">
    <template #content>
      <a-list class="account-info-list scroll-popover-content" v-if="user">
        <a-list-item
          class="account-info-list-item"
          @mouseenter="showMaintain = true"
          @mouseleave="showMaintain = false"
        >
          <a-space>
            <UserOutlined />
            <div class="user-name ellipsis">
              {{ user?.name || '请设置用户名' }}
            </div>
          </a-space>
          <a @click="maintainUserInfo" v-if="showMaintain"> 修改 </a>
        </a-list-item>
        <a-list-item class="account-info-list-item">
          <a-space>
            <SwapOutlined />
            <span>请假&销假</span>
            <a-tooltip>
              <template #title>
                如需请假或销假，可切换右面的开关状态。在请假状态下，不会为您安排新的任务。
              </template>
              <QuestionCircleOutlined />
            </a-tooltip>
          </a-space>
          <a-switch
            :checked="user.workStatus === UserWorkStatus.LEAVE"
            @change="changeLeaveStatus"
            checked-children="请假中"
            un-checked-children="未请假"
          />
        </a-list-item>
        <a-list-item class="account-info-list-item" @click="userLogout">
          <a-space>
            <LogoutOutlined />
            <div>退出登录</div>
          </a-space>
        </a-list-item>
      </a-list>
      <a-flex :align="'center'" vertical v-else>
        <WechatWebLogin container-id="header-wx-web-login" />
      </a-flex>
    </template>
    <a-avatar shape="square" style="background-color: #00a1f1">
      <template #icon>
        <UserOutlined />
      </template>
    </a-avatar>
  </a-popover>
  <a-modal
    :open="showModal"
    :confirm-loading="editLoading"
    title="维护个人信息"
    @ok="doMaintain"
    @cancel="showModal = false"
  >
    <a-form
      ref="maintainFormRef"
      :model="accountInfo"
      :rules="accountRules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item label="用户名" name="name">
        <a-input
          v-model:value="accountInfo.name"
          placeholder="请输入新用户名"
          show-count
          :maxlength="20"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<style scoped lang="less">
.account-info-list {
  width: 240px;
  & .account-info-list-item {
    border-radius: 10px;
    & div.user-name {
      width: 140px;
    }
    &:hover {
      cursor: pointer;
      background-color: #ddd;
    }
  }
}

.scroll-popover-content {
  max-height: 200px;
  overflow-y: auto;
}
</style>
