import {
  CONFIG_ITEM_SYSTEM_GUIDE,
  CONFIG_ITEM_SYSTEM_INDEX,
  CONFIG_ITEM_SYSTEM_PRICING,
  CONFIG_TYPE_SYSTEM,
  type SystemConfig,
  type SystemGuideConfig,
  type SystemIndexConfig,
  type SystemPricingConfig
} from '@/api/system/config'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useConfigStore = defineStore('config', () => {
  const systemIndexConfig = ref<SystemIndexConfig | null>(null)
  const systemPricingConfig = ref<SystemPricingConfig | null>(null)
  const systemGuideConfig = ref<SystemGuideConfig | null>(null)
  const loaded = ref(false)

  const updateIndexConfig = (config: SystemIndexConfig) => {
    systemIndexConfig.value = config
  }
  const updatePricingConfig = (config: SystemPricingConfig) => {
    systemPricingConfig.value = config
  }
  const updateGuideConfig = (config: SystemGuideConfig) => {
    systemGuideConfig.value = config
  }
  const updateConfigs = (configs: SystemConfig<any>[]) => {
    resetConfigs()
    configs
      .filter((conf) => conf.type === CONFIG_TYPE_SYSTEM)
      .forEach((conf) => {
        switch (conf.item) {
          case CONFIG_ITEM_SYSTEM_INDEX:
            updateIndexConfig(conf.value)
            break
          case CONFIG_ITEM_SYSTEM_PRICING:
            updatePricingConfig(conf.value)
            break
          case CONFIG_ITEM_SYSTEM_GUIDE:
            updateGuideConfig(conf.value)
            break
        }
      })
    loaded.value = true
  }
  const resetConfigs = () => {
    systemIndexConfig.value = null
    systemPricingConfig.value = null
    systemGuideConfig.value = null
    loaded.value = false
  }
  return {
    systemIndexConfig,
    systemPricingConfig,
    systemGuideConfig,
    loaded,
    // updateIndexConfig,
    // updatePricingConfig,
    // updateGuideConfig,
    updateConfigs,
    resetConfigs
  }
})
