import type { NoticeType } from '.'
import { MicroSvrAddrs } from '../common/config'
import SockJS from 'sockjs-client'
// import SockJS from 'sockjs-client/dist/sockjs.js';

export enum ReplyMsgType {
  RECEIVED = 'RECEIVED', // 收到
  READ = 'READ', // 已读
  REPLY = 'REPLY', // 回复
  CLOSE = 'CLOSE' // 关闭ws
}

export interface WsReplyData {
  id: string | number
  type: NoticeType
  msgType: ReplyMsgType
}

export function establishWebSocket(): WebSocket {
  const baseUrl = import.meta.env.VITE_COMMON_API_BASE_URL as string
  const socket = new SockJS(`${baseUrl}${MicroSvrAddrs.taskania}/notice/ws`)
  // const parts = baseUrl.split('://')
  // const socket = new WebSocket(
  //   `${parts[0] === 'https' ? 'wss' : 'ws'}://${parts[1]}${MicroSvrAddrs.taskania}/notice/ws`
  // )
  socket.onopen = (event: Event) => {
    console.log('通知&消息WS连接打开', event)
  }
  socket.onerror = (event: Event) => {
    console.log('通知&消息WS发生错误', event)
  }
  socket.onclose = (event: CloseEvent) => {
    console.log('通知&消息WS连接关闭', event)
  }
  return socket
}
