import type {
  BarSeriesOption,
  GraphSeriesOption,
  LineSeriesOption,
  PieSeriesOption
} from 'echarts/charts'
import { BarChart, GraphChart, LineChart, PieChart } from 'echarts/charts'
import type {
  DataZoomComponentOption,
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  TitleComponentOption,
  TooltipComponentOption
} from 'echarts/components'
import {
  DataZoomComponent,
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components'
import type { ComposeOption } from 'echarts/core'
import { use } from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'

export const initEchartsUse = () => {
  use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
    DatasetComponent,
    PieChart,
    BarChart,
    LineChart,
    GraphChart,
    SVGRenderer
  ])
}

export type EChartsOption = ComposeOption<
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
  | DataZoomComponentOption
  | DatasetComponentOption
  | PieSeriesOption
  | BarSeriesOption
  | LineSeriesOption
  | GraphSeriesOption
>

export type EchartTypeName = 'bar' | 'line' | 'pie' | 'graph'

export interface EchartDatasetProp<T> {
  key: keyof T // 具体取值在每个数据对象(object)中的 key
  name: string // 在数据集中的名称
}

export interface EchartValuedDataItem<T> {
  value: T | T[]
  name: string
}

// Echart鼠标事件参数
export type EchartMouseEventParams = {
  // 当前点击的图形元素所属的组件名称，
  // 其值如 'series'、'markLine'、'markPoint'、'timeLine' 等。
  componentType: string
  // 系列类型。值可能为：'line'、'bar'、'pie' 等。当 componentType 为 'series' 时有意义。
  seriesType: string
  // 系列在传入的 option.series 中的 index。当 componentType 为 'series' 时有意义。
  seriesIndex: number
  // 系列名称。当 componentType 为 'series' 时有意义。
  seriesName: string
  // 数据名，类目名
  name: string
  // 数据在传入的 data 数组中的 index
  dataIndex: number
  // 传入的原始数据项
  data: any
  // sankey、graph 等图表同时含有 nodeData 和 edgeData 两种 data，
  // dataType 的值会是 'node' 或者 'edge'，表示当前点击在 node 还是 edge 上。
  // 其他大部分图表中只有一种 data，dataType 无意义。
  dataType: string
  // 传入的数据值
  value: number | Array<number>
  // 数据图形的颜色。当 componentType 为 'series' 时有意义。
  color: string
}

// Echart图例选择事件参数
export type EchartLegendSelectEventParams = {
  type:
    | 'legendselectchanged'
    | 'legendselected'
    | 'legendunselected'
    | 'legendselectall'
    | 'legendinverseselect'
  // 切换的图例名称
  name?: string
  // 所有图例的选中状态表
  selected: {
    [name: string]: boolean
  }
}
