import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import axios from 'axios'
// import type { ResponseBody } from '@/api/typing';
// import { message } from 'ant-design-vue'
// import type { BaseResponse } from '@/api/common/entity'

// 创建 axios 实例
const request = axios.create({
  baseURL: import.meta.env.VITE_COMMON_API_BASE_URL, // api base_url
  timeout: 30000, // 请求超时时间
  validateStatus: (status) => {
    return status >= 200 && status < 300
  }
})

// 请求异常拦截处理器
const requestErrorHandler = (error: AxiosError): Promise<any> => {
  // 请求时发生错误，直接替换为500页面
  // router.replace('/err/500')
  return Promise.reject(error)
}

// 请求拦截器
const requestHandler = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig> => {
  return config
}

// Add a request interceptor
request.interceptors.request.use(requestHandler, requestErrorHandler)

// 响应拦截器
const responseHandler = (response: AxiosResponse<any>) => {
  // 302 未登录/已过期,跳转到首页
  // if (response.status === 302) {
  //   Modal.info({
  //     title: '登录超时',
  //     content: '登录超时，请重新登录',
  //     onOk: () => (window.location.href = '/index.html')
  //   })
  // }
  return response.data
}

// 响应异常拦截处理器
const responseRequestErrorHandler = (error: AxiosError): Promise<any> => {
  // 处理4xx的响应码
  // if (error.response && error.response.status >= 400 && error.response.status < 500) {
  //   message.warn((error.response.data as BaseResponse).message)
  // }
  if (error.response) {
    // 暂时没有必要针对页面上异步操作导致的403、404、500错误进行跳转处理，各个页面自行处理即可
    // if (error.response.status === 403) {
    //   // 出现403错误，替换为403页面
    //   router.replace('/err/403')
    // } else if (error.response.status === 404) {
    //   // 出现404错误，替换为404页面
    //   router.replace('/err/404')
    // } else if (error.response.status === 500) {
    //   // 出现500错误，替换为500页面
    //   router.replace('/err/500')
    // }
    // 暂时只有workbench中会出现401错误，所以暂时不用处理
  }
  return Promise.reject(error)
}

// Add a response interceptor
request.interceptors.response.use(responseHandler, responseRequestErrorHandler)

export default request
