<script setup lang="ts">
import {
  fetchUserTodoList,
  deleteUserTodo,
  createUserTodo,
  updateUserTodo,
  type UserTodo,
  finishUserTodo
} from '@/api/user/todo'
import { PlusOutlined, ScheduleOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import type { Rule } from 'ant-design-vue/es/form'
import dayjs from 'dayjs'
import { onMounted, ref, reactive, type RendererElement } from 'vue'

function refreshList() {
  fetchUserTodoList().then(
    (res) => {
      todoDatas.value = res.queryData
    },
    (err) => {
      message.error(err.response?.data.message || '查询您的当前待办列表失败')
    }
  )
}

const todoDatas = ref<UserTodo[]>([])
onMounted(refreshList)

const visible = ref(false)
const showEditTodo = ref(false)
const editModalTitle = ref('新增个人待办')
const editLoading = ref(false)
const userTodoFormRef = ref<RendererElement>()

interface UserTodoEditInfo {
  id: string
  title: string
  description: string
}

const userTodoInfo = reactive<UserTodoEditInfo>({
  id: '',
  title: '',
  description: ''
})
const userTodoRules: Record<string, Rule[]> = {
  title: [
    { required: true, message: '必须输入待办标题', trigger: 'change' },
    { min: 1, max: 50, message: '待办标题长度必须在1-50之间', trigger: 'blur' }
  ],
  description: [
    // { required: true, message: '必须输入待办详细说明', trigger: 'change' },
    { min: 1, max: 200, message: '待办详细说明长度必须在1-200之间', trigger: 'blur' }
  ]
}

const openAddModal = () => {
  visible.value = false
  showEditTodo.value = true
  editModalTitle.value = '新增个人待办'
  userTodoInfo.id = ''
  userTodoInfo.title = ''
  userTodoInfo.description = ''
}

const openEditTodo = (todo: UserTodo) => {
  visible.value = false
  showEditTodo.value = true
  editModalTitle.value = '修改个人待办'
  userTodoInfo.id = todo.id
  userTodoInfo.title = todo.title
  userTodoInfo.description = todo.description
}

const doEditTodo = () => {
  editLoading.value = true
  userTodoFormRef.value
    ?.validate()
    .then(() => {
      if (userTodoInfo.id) {
        updateUserTodo(userTodoInfo.id, {
          title: userTodoInfo.title,
          description: userTodoInfo.description || ''
        }).then(
          () => {
            refreshList()
            editLoading.value = false
            showEditTodo.value = false
            message.success('修改个人待办成功')
          },
          (err) => {
            editLoading.value = false
            message.error(err.response?.data.message || '修改个人待办失败')
          }
        )
      } else {
        createUserTodo({ title: userTodoInfo.title, description: userTodoInfo.description }).then(
          () => {
            refreshList()
            editLoading.value = false
            showEditTodo.value = false
            message.success('创建个人待办成功')
          },
          (err) => {
            editLoading.value = false
            message.error(err.response?.data.message || '创建个人待办失败')
          }
        )
      }
    })
    .catch(() => {
      editLoading.value = false
    })
}

const todoDetailInfo = ref<UserTodo>({} as UserTodo)
const showTodoDetail = ref(false)
const openTodoDetail = (todo: UserTodo) => {
  visible.value = false
  todoDetailInfo.value = todo
  showTodoDetail.value = true
}

interface UserTodoFinishInfo {
  id: string
  startTime?: dayjs.Dayjs
  endTime: dayjs.Dayjs
}

const showFinishTodo = ref(false)
const finishLoading = ref(false)
const finishTodoFormRef = ref<RendererElement>()

const finishTodoInfo = reactive<UserTodoFinishInfo>({
  id: '',
  startTime: undefined,
  endTime: dayjs()
})
const finishTodoRules: Record<string, Rule[]> = {
  startTime: [{ required: true, message: '必须指定开始时间', trigger: 'change' }],
  endTime: [
    { required: true, message: '必须指定结束时间', trigger: 'change' },
    {
      validator: (rule, value) => {
        if (!value) {
          return Promise.resolve()
        }
        if (!(value as dayjs.Dayjs).isAfter(finishTodoInfo.startTime!)) {
          return Promise.reject('结束时间必须晚于开始时间')
        }
        return Promise.resolve()
      }
    }
  ]
}

const openFinishTodo = (todo: UserTodo) => {
  visible.value = false
  showFinishTodo.value = true
  finishTodoInfo.id = todo.id
  finishTodoInfo.startTime = undefined
  finishTodoInfo.endTime = dayjs()
}

const doFinishTodo = () => {
  finishLoading.value = true
  finishTodoFormRef.value
    ?.validate()
    .then(() => {
      const actualTimeInHours = finishTodoInfo.endTime.diff(finishTodoInfo.startTime!, 'hour', true)
      finishUserTodo(finishTodoInfo.id, {
        startTime: finishTodoInfo.startTime!.toDate(),
        endTime: finishTodoInfo.endTime.toDate(),
        actualTimeInHours
      }).then(
        () => {
          refreshList()
          finishLoading.value = false
          showFinishTodo.value = false
          message.success('完成个人待办成功')
        },
        (err) => {
          finishLoading.value = false
          message.error(err.response?.data.message || '完成个人待办失败')
        }
      )
    })
    .catch(() => {
      finishLoading.value = false
    })
}

const deleteTodoDetail = (todo: UserTodo) => {
  visible.value = false
  const okButtonProps = {
    danger: true
    // loading: false
  }
  Modal.confirm({
    title: '确定删除个人待办？',
    content: `即将删除《${todo.title}》，是否继续？`,
    okButtonProps,
    onOk() {
      return new Promise((resolve, reject) => {
        // okButtonProps.loading = true
        deleteUserTodo(todo.id).then(
          () => {
            refreshList()
            // okButtonProps.loading = false
            message.success('删除个人待办成功')
            resolve(todo.id)
          },
          (err) => {
            // okButtonProps.loading = false
            message.error(err.response?.data.message || '删除个人待办失败')
            reject()
          }
        )
      })
    }
  })
}
</script>

<template>
  <a-popover v-model:open="visible" trigger="hover" placement="bottomRight">
    <template #title>
      <a-flex :justify="'space-between'" :align="'center'">
        <span>个人待办</span>
        <a @click="openAddModal" style="font-weight: normal"><PlusOutlined /> 新增待办</a>
      </a-flex>
    </template>
    <template #content>
      <a-list
        :data-source="todoDatas"
        class="scroll-popover-content"
        style="max-height: 450px; overflow-y: auto"
      >
        <template #renderItem="{ item }">
          <a-list-item>
            <div class="ellipsis" style="width: 240px">{{ item.title }}</div>
            <template #actions>
              <a-space size="small">
                <a @click="openEditTodo(item)">修改</a>
                <a @click="openTodoDetail(item)">详情</a>
                <a @click="openFinishTodo(item)">完成</a>
                <a @click="deleteTodoDetail(item)">删除</a>
              </a-space>
            </template>
          </a-list-item>
        </template>
      </a-list>
    </template>
    <a-badge :count="todoDatas.length">
      <a-avatar shape="square" style="background-color: #f65314">
        <template #icon>
          <ScheduleOutlined />
        </template>
      </a-avatar>
    </a-badge>
  </a-popover>
  <a-modal
    :open="showEditTodo"
    :confirm-loading="editLoading"
    :title="editModalTitle"
    @ok="doEditTodo"
    @cancel="showEditTodo = false"
  >
    <a-form
      ref="userTodoFormRef"
      :model="userTodoInfo"
      :rules="userTodoRules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item label="标题" name="title">
        <a-input
          v-model:value="userTodoInfo.title"
          placeholder="请输入待办标题"
          show-count
          :maxlength="50"
        />
      </a-form-item>
      <a-form-item label="详细说明" name="description">
        <a-textarea
          v-model:value="userTodoInfo.description"
          placeholder="请输入待办详细说明"
          :auto-size="{ minRows: 2, maxRows: 5 }"
        />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    :open="showTodoDetail"
    title="个人待办详情"
    :footer="null"
    @cancel="showTodoDetail = false"
  >
    <a-descriptions :column="1">
      <a-descriptions-item label="标题">{{ todoDetailInfo.title }}</a-descriptions-item>
      <a-descriptions-item label="详细说明">{{ todoDetailInfo.description }}</a-descriptions-item>
    </a-descriptions>
  </a-modal>
  <a-modal
    :open="showFinishTodo"
    :confirm-loading="finishLoading"
    title="完成个人待办"
    @ok="doFinishTodo"
    @cancel="showFinishTodo = false"
  >
    <a-form
      ref="finishTodoFormRef"
      :model="finishTodoInfo"
      :rules="finishTodoRules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-item label="开始时间" name="startTime">
        <a-date-picker
          v-model:value="finishTodoInfo.startTime"
          format="YYYY-MM-DD HH:mm"
          :showTime="{ format: 'HH:mm' }"
          placeholder="请指定处理此待办的开始时间"
          style="width: 100%"
        />
      </a-form-item>
      <a-form-item label="结束时间" name="endTime">
        <a-date-picker
          v-model:value="finishTodoInfo.endTime"
          format="YYYY-MM-DD HH:mm"
          :showTime="{ format: 'HH:mm' }"
          placeholder="请指定处理此待办的结束时间"
          style="width: 100%"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<style scoped lang="less"></style>
